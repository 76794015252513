import {
  Card,
  CardContent,
  Typography,
  Grid,
  TableCell,
  TableRow,
  Modal,
  CardHeader,
  TableBody,
  Button,
  Table,
  TableContainer,
  TableHead,
  Chip,
  makeStyles,
  IconButton,
  CardActions
} from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import IconClose from '@material-ui/icons/Close'
import moment from 'moment'
import * as API from 'services/api';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import useRouter from 'utils/useRouter';
import { SERVICES_LOGOS, SERVICES_LINK } from 'config'
import { ReactComponent as EnvelopeIcon } from 'assets/svgs/Envelope.svg';

const ACTIVATED_BUTTOND = {
  "2000": { //"ALERT Santé Sécurité"
    href: 'https://avertinone.premium.apavesuite.com/ '
  },
  "2002": { //CHECK Santé Sécurité
    href: 'https://checksantesecurite.apavesuite.com/user/login?destination=/'
  },
  "2003": {//CHECK Environnement
    href: "https://audit.apaveconnect.com/user/login"
  },
  "2004": {//CHECK Access
    href: "https://infrastructures-construction.france.apave.com/fr-FR/Prestation/Check-Access-Controle-preventif-de-l-acces-aux-chantiers"
  },
  "2008": {//PILOT Veille
    href: "https://www.oneapave.com/"
  },
  "2009": {//Pilot Immo
    href: "https://infrastructures-construction.france.apave.com/fr-FR/Vos-besoins/Decouvrir-nos-gammes-de-solutions-digitales/Pilot-Immo"
  },
  // "2013": {//Apogée One
  //   href: "https://www.oneapave.com/Login?ReturnUrl=/"
  // },
  // "2014": {//Manage inOne
  //   href: "https://www.oneapave.com/Login?ReturnUrl=/"
  // },
  // "2008": { //Avise inOne
  //   href: "https://www.oneapave.com/Login?ReturnUrl=/"
  // }
}

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: 15,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1),
    position: 'relative'
  },
  chip: {
    borderRadius: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    backgroundColor: 'transparent'
  },
  content: {
    marginTop: theme.spacing(2)
  },
  discoverBtn: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 100
  },
  sectiontitle: {
    marginBottom: theme.spacing(1)
  },
  infochip: {
    borderRadius: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    backgroundColor: '#EFF8F2',
    color: theme.palette.app.primary
  },
  heart: {
    fill: "#DE5252",
    color: "#DE5252"
  },
  logo: {
    maxHeight: 42
  },
  logowrap: {
    position: 'absolute',
    left: 0,
    top: 0
  },
  description: {
    color: '#464F73',
    whiteSpace: 'pre-line'
  },
  contactBtn: {
    textTransform: 'none', marginBottom: 16,
    color: theme.palette.app.primary,
    fill: theme.palette.app.primary
  },
  contacticon: {
    color: theme.palette.app.primary,
    fill: theme.palette.app.primary,
    marginBottom: 2
  }
}));

const ApplicationContentItem = props => {
  const {
    id,
    appId,
    title,
    active,
    onBookMark,
    bookmarked,
    onContact,
    group,
    description
  } = props;

  const classes = useStyles();
  const router = useRouter();

  const onLearnMore = () => {
    window.open(
      'https://www.apave.com/service-en-ligne-notre-offre'
    );
  }
  const onConnect = () => {
    if (ACTIVATED_BUTTOND[appId] && ACTIVATED_BUTTOND[appId].path) {
      router.history.push(ACTIVATED_BUTTOND[appId].path);
      return
    }

    if (ACTIVATED_BUTTOND[appId] && ACTIVATED_BUTTOND[appId].href) {
      window.open(ACTIVATED_BUTTOND[appId].href);
      return
    }

  }

  const link = SERVICES_LINK[title];
  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Grid container>
              {/* <Chip color="primary" size={"small"} label={group} className={classes.infochip} /> */}
              {
                SERVICES_LOGOS.hasOwnProperty(title) ?
                  <img src={SERVICES_LOGOS[title]} className={classes.logo} /> :
                  <Typography variant="h5" className={classes.serviceTitle}>
                    <strong>{title}</strong>
                  </Typography>
              }
            </Grid>
          </Grid>
          <Grid item>
            <Grid container>
              <IconButton style={{ padding: 3 }} onClick={onBookMark(id)}>{bookmarked ? <FavoriteIcon className={classes.heart} /> : <FavoriteBorderIcon className={classes.heart} />}</IconButton>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.content}>
          <Typography paragraph className={classes.description}>{description}</Typography>
        </div>
      </CardContent>
      <CardActions style={{ paddingTop: 0, justifyContent: 'center', paddingBottom: 8, flexWrap: 'wrap' }}>
        {
          active ?
            <Button
              variant="contained"
              className={classes.discoverBtn}
              size="small"
              style={{ textTransform: 'none' }}
              onClick={onConnect}
            >
              {'ACCEDER AU SERVICE'}
            </Button> :
            <>
              <Button
                variant="contained"
                className={classes.discoverBtn}
                size="small"
                style={{ textTransform: 'none' }}
                onClick={() => {
                  window.open(link ? link : "https://www.apave.fr/les-solutions-digitales-apave", "_blank")
                }}
              >
                <FormattedMessage id="applications.linkbtn" />
              </Button>
              {/* <Button
                size="small"
                className={classes.contactBtn}
                onClick={onContact(appId)}
                startIcon={<EnvelopeIcon className={classes.contacticon} />}
              >
                <FormattedMessage id="applications.contactbtn" />
              </Button> */}
            </>
        }
      </CardActions>
    </Card>
  );
};

ApplicationContentItem.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }),
};

export default injectIntl(ApplicationContentItem);
