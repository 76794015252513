export const ACTION_UPDATE_APP = 'ACTION_UPDATE_APP';
export const ACTION_SET_LOADING = 'ACTION_SET_LOADING';
export const ACTION_INIT_APP = 'ACTION_INIT_APP';
export const ACTION_SHOW_IMAGE_MODAL = 'ACTION_SHOW_IMAGE_MODAL';
export const ACTION_REFRESH_APP = 'ACTION_REFRESH_APP';
// auth
export const ACTION_UPDATE_AUTH = 'ACTION_UPDATE_AUTH';
export const ACTION_INIT_AUTH = 'ACTION_INIT_AUTH';
export const ACTION_LOGIN = 'ACTION_LOGIN';
export const ACTION_SIGNUP = 'ACTION_SIGNUP';
export const ACTION_LOGOUT = 'ACTION_LOGOUT';

// language
export const CHANGE_LOCALE = 'app/LanguageToggle/CHANGE_LOCALE';

//cart
export const ACTION_UPDATE_INPRODUCTS= 'ACTION_UPDATE_INPRODUCTS';
export const ACTION_UPDATE_APAVEPRODUCTS= 'ACTION_UPDATE_APAVEPRODUCTS';


export const ACTION_UPDATE_SESSIONS= 'ACTION_UPDATE_SESSIONS';
export const ACTION_SET_CONTACTMODAL= 'ACTION_SET_CONTACTMODAL';