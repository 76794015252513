import { postData, getData, deleteData, patchData, putData } from './base';
import { authApiUrl, APP_ID, apiURL } from 'config';
import qs from 'querystring';

const apiEndPoints = {
  login: authApiUrl + 'apave-auth/login',
  signup: authApiUrl + 'apave-auth/register',
  loginWithCode: authApiUrl + 'apave-auth/loginWithCode',
  sendCode: authApiUrl + 'apave-auth/send-code',
  sendResetPasswordLink: authApiUrl + 'apave-auth/send-reset-password-link',
  verifyResetPasswordLink: authApiUrl + 'apave-auth/verify-reset-password-link',
  resetPasswordWithToken: authApiUrl + 'apave-auth/reset-password-with-token',
  refreshToken: authApiUrl + 'apave-auth/refresh-token',
  changePassword: authApiUrl + 'users/change-password',
  sendVerificationEmail: authApiUrl + 'apave-auth/send-verification-email',
  verifyEmail: authApiUrl + 'apave-auth/verify-email',
  signout: authApiUrl + 'apave-auth/sign-out',
  profile: authApiUrl + 'users/profile',
  updateProfile: authApiUrl + 'users/',
  apaveApps: authApiUrl + 'apave-apps',
  request: authApiUrl + 'leads/userspaces',
  mobility: authApiUrl + 'users/mobilityuser',
  order: authApiUrl + 'leads/orders/',
  location: authApiUrl + 'location/',
  clients: authApiUrl + 'admins/clients/',
  listsirets: authApiUrl + 'apave-apps/list-sirets/',
  observations: authApiUrl + 'observations/',
  codeproducts: authApiUrl + 'codeproducts/',

  //product
  catalogue: authApiUrl + 'catalogue/',
  product_national: authApiUrl + 'product_national/',
  training: authApiUrl + 'training/',
  products: authApiUrl + 'products',
  sessions: authApiUrl + 'sessions',
  caracteristiques: authApiUrl + 'caracteristiques',
  interventions: authApiUrl + 'interventions',
  consolekpi: authApiUrl + 'kpi',

  actions: apiURL + 'actions/',
  documentFiles: apiURL + 'documents/files/',
  documentFolders: apiURL + 'documents/folders/',
  documentFilesPreSignedURLs: apiURL + 'documents/files/pre-signed',
  apaveDocumentsQuery: apiURL + 'documents/apave/latest',
  weldingDocuments: apiURL + 'weldings/documents',
  orders: 'orders',
  invoices: 'invoices',
  trainees: 'trainees',
  kpi: 'kpi',
  geddocument: "documents",
  caces: 'caces',

  employee: authApiUrl + 'employee',
  leadsPreSignedURLs: apiURL + 'documents/leads/pre-signed',
  sendEmail: authApiUrl + 'apave-apps/sendEmail',
  uploadToS3: 'documents/uploadToS3',

  evaluations: 'evaluations',
};

export const signin = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.login, { ...body, appId: APP_ID })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const refreshTokenAPI = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.refreshToken, { ...body, appId: APP_ID })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const signout = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.signout, { ...body, appId: APP_ID })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const changePassword = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.changePassword, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const getProfile = () =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.profile)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const updateProfile = (id, body) =>
  new Promise((resolve, reject) => {
    patchData(apiEndPoints.updateProfile + id, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const getMobilityUser = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.mobility, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const listApaveApps = () =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.apaveApps)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const signinWithCode = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.loginWithCode, { ...body, appId: APP_ID })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const sendCode = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.sendCode, { ...body, appId: APP_ID })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const sendResetPasswordLink = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.sendResetPasswordLink, { ...body, appId: APP_ID })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const verifyResetPasswordLink = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.verifyResetPasswordLink, { ...body, appId: APP_ID })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const resetPasswordWithToken = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.resetPasswordWithToken, { ...body, appId: APP_ID })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const signup = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.signup, { ...body, appId: APP_ID })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const sendVerificationEmail = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.sendVerificationEmail, { ...body, appId: APP_ID })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const verifyEmail = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.verifyEmail, { ...body, appId: APP_ID })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

// actions
export const listActions = body =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.actions + '?' + qs.stringify(body))
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const createAction = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.actions, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const getAction = id =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.actions + id)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const updateAction = (id, body) =>
  new Promise((resolve, reject) => {
    patchData(apiEndPoints.actions + id, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const removeAction = id =>
  new Promise((resolve, reject) => {
    deleteData(apiEndPoints.actions + id)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

// https://www.apave.com/jsonapi/node/article?sort=-created&filter[status][value]=1&page[limit]=12&page[offset]=0

export const getLatestApaveNews = async () => {
  const apaveHostName = 'https://www.apave.com/';
  const newsEndPoint =
    apaveHostName +
    'jsonapi/node/article?sort=-created&filter[status][value]=1&page[limit]=12&page[offset]=0';
  const ApaveHostUrl = apaveHostName;
  const ImageInfoUrl = apaveHostName + 'jsonapi/file/image/';

  try {
    const response = await fetch(newsEndPoint);
    const news = (await response.json()).data;
    const result = [];
    for (let index1 = 0; index1 < news.length; index1++) {
      const element = news[index1];
      const obj = {
        id: element.id,
        title: element.attributes.title,
        created: element.attributes.created,
        image: '',
      };
      if (
        element.relationships.image.data !== undefined &&
        element.relationships.image.data !== null
      ) {
        let url = ImageInfoUrl + element.relationships.image.data.id;

        const imageInfo = await (await fetch(url)).json();
        if (imageInfo.data !== undefined) {
          const imageUrl = ApaveHostUrl + imageInfo.data.attributes.uri.url;

          obj.image = imageUrl;
        }
      }
      result.push(obj);
    }
    return Promise.resolve(result);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const uploadFiles = (path, formData) =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.documentFiles + path, formData)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const deleteFile = path =>
  new Promise((resolve, reject) => {
    deleteData(apiEndPoints.documentFiles + path)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const listFilesUnderDirectory = path =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.documentFiles + path)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const createDirectory = (path, title) =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.documentFolders + path, { title })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const deleteFolder = path =>
  new Promise((resolve, reject) => {
    deleteData(apiEndPoints.documentFolders + path)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const getPreSignedURLs = payload =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.documentFilesPreSignedURLs, payload)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const getPreSignedURLsForLeads = payload =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.leadsPreSignedURLs, payload)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const uploadFileToS3 = (urlInfo, file) =>
  new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.keys(urlInfo.fields).forEach(key => {
      formData.append(key, urlInfo.fields[key]);
    });
    formData.append('file', file);
    postData(apiEndPoints.uploadToS3, formData)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const queryLatestApaveDocuments = limit =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.apaveDocumentsQuery, { limit })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const listWeldingDocument = () =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.weldingDocuments)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const createRequest = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.request, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });


export const listCatalogues = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.catalogue}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const listPublicCatalogues = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.catalogue}public?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};

export const listOrders = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.orders}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const listInvoices = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.invoices}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const listTrainees = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.trainees}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const listCaces = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.caces}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const listRequests = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.request}/get?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const listMessages = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.request}/messages?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const sendMessage = body =>
  new Promise((resolve, reject) => {
    postData(`${apiEndPoints.request}/messages`, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const getKPIData = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.kpi, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const callTrainingAPI = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.training, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const createOrder = body =>
  new Promise((resolve, reject) => {
    postData(`${apiEndPoints.order}`, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const createOrderPack = body =>
  new Promise((resolve, reject) => {
    postData(`${apiEndPoints.order}pack`, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const getMyOrders = () =>
  new Promise((resolve, reject) => {
    getData(`${apiEndPoints.order}get`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const reducedLocations = () =>
  new Promise((resolve, reject) => {
    getData(`${apiEndPoints.location}reduced`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const listGEDApaveDocuments = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.geddocument}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const listEmployees = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.employee}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const createEmployee = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.employee, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const importEmployees = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.employee + "/imports", body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const listClients = () =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.clients)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const listProductTraining = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.products}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const listSessions = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.sessions}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const listCaracteristiques = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.caracteristiques}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const listInterventions = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.interventions}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const listProductNational = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.product_national}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const listSirets = () =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.listsirets)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
//Observations
export const createObservation = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.observations, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const getObservation = id =>
  new Promise((resolve, reject) => {
    getData(apiEndPoints.observations + id)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const updateObservation = (id, body) =>
  new Promise((resolve, reject) => {
    patchData(apiEndPoints.observations + id, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const removeObservation = id =>
  new Promise((resolve, reject) => {
    deleteData(apiEndPoints.observations + id)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const sendEmail = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.sendEmail, { ...body, appId: APP_ID })
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
export const listCodeProducts = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.codeproducts}reduced?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};
export const getKPIDataFromConsole = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.consolekpi, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const importTraines = body =>
  new Promise((resolve, reject) => {
    postData(apiEndPoints.trainees + "/import-reduced", body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const getProductPDF = body =>
  new Promise((resolve, reject) => {
    postData(`${apiEndPoints.products}/generatePDF`, body)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });

export const listEvaluations = params => {
  const query = Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&');

  return new Promise((resolve, reject) => {
    getData(`${apiEndPoints.evaluations}?${query}`)
      .then(res => resolve(res.data))
      .catch(err => reject(err));
  });
};