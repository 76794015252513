import {
    Card,
    CardContent,
    Typography,
    Grid,
    Modal,
    CardHeader,
    IconButton,
    Button,
    makeStyles
} from '@material-ui/core';
import { FormattedMessage, injectIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as API from 'services/api';
import moment from 'moment'
import CloseIcon from '@material-ui/icons/Close';
import { CustomTextInput, CustomTable } from 'components'
import { useDispatch } from 'react-redux';
import _ from "lodash";
import { removeDuplicateFromArray } from 'utils';
import { ReactComponent as FileIcon } from 'assets/svgs/doc_file.svg';
import IconEye from '@material-ui/icons/Visibility'
import { ReactComponent as DownloadIcon } from 'assets/svgs/doc_download.svg';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { setLoading } from 'redux/actions/app';
import { apiURL } from 'config'
import axios from 'axios';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'absolute',
        overflow: 'scroll',
        height: '100vh',
        display: 'flex',
        zIndex: '3000 !important'
    },
    backdrop: {
        backgroundColor: `white !important`,
        opacity: 0.9
    },
    inner: {
        width: '100%',
        margin: "auto",
        overflow: 'scroll',
        maxWidth: 600,
        //display: 'flex',
        backgroundColor: 'white',
        '&:focus': {
            outline: 'none !important'
        },
        '&::-webkit-scrollbar': {
            display: 'none'
        },
        position: 'relative',
        borderRadius: 10
    },
    content: {
        marginTop: theme.spacing(2),
    },
    closebtn: {
        borderRadius: 100
    },
    cardHeader: {
        backgroundColor: theme.palette.app.primary
    },
    headerTitle: {
        color: 'white',
        fontSize: 20,
        lineHeight: '25px'
    },
}));


const TraineePage = props => {
    const {
        intl: { formatMessage },
        open,
        closeModal
    } = props;
    const classes = useStyles();

    return (
        <Modal
            className={classes.root}
            open={open}
            onClose={closeModal}
            aria-labelledby="users-modal-title"
            aria-describedby="users-modal-description"
            //disableBackdropClick
            BackdropProps={{ className: classes.backdrop }}
        >
            <Card className={classes.inner}>
                <CardHeader
                    title={"Votre avis sur l’espace client Apave"}
                    titleTypographyProps={{ className: classes.headerTitle }}
                    className={classes.cardHeader}
                    action={
                        <IconButton onClick={closeModal}>
                            <CloseIcon style={{ fill: 'white' }} />
                        </IconButton>
                    }
                />
                <CardContent>
                    <div>
                        <Typography variant="h6"><strong>Bonjour,<br />
                            Votre avis compte pour nous ! Aidez-nous à rendre votre espace client encore plus intuitif et efficace. Cliquez simplement sur le lien ci-dessous pour partager votre expérience.<br /><br />
                            Chaque retour nous permet d’améliorer nos services et de simplifier la gestion de vos formations.<br /><br />
                            Merci pour votre contribution ! <br /><br /></strong></Typography>
                        <Grid container justify='center'><Button
                            variant='contained'
                            color="primary"
                            style={{ textTransform: "none", borderRadius: 4, backgroundColor: "#05A969" }}
                            onClick={() => {
                                window.open(`https://docs.google.com/forms/d/e/1FAIpQLScfVnptEMwZlztIyo-GJ2W6xs_1MtTHE0nRBAIPjf31l4-Z9g/viewform`, "_blank");
                            }}
                        >Je donne mon avis</Button></Grid>
                    </div>
                </CardContent>
            </Card>
        </Modal >
    );
};

TraineePage.propTypes = {
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired
    }),
};

export default injectIntl(TraineePage);
